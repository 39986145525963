import axios from 'axios';
import Url from './Url';

export default axios.create({
   // baseURL: `https://stockbackend.netlabsofficial.com/`,
    baseURL: Url,

    headers:{
      'X-Requested-With':'XMLHttpRequest',
      'Accept':'Application/json'
    },
    withCredentials: true,
  });